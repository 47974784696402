import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Head from 'next/head';
import criticalCss from 'plaid-threads/utils/criticalCss'; // eslint-disable-line
import parsePath from 'src/lib/parse-path';
import isEmpty from 'lodash/isEmpty';

// components
import OpenGraphData from './OpenGraphData.tsx';
import Fonts from './Fonts';

// helpers
import { isProduction } from 'src/lib/constants';
import { appendTrailingSlash } from 'src/lib/utils';
import { optimizeImage } from 'src/lib/image-helpers';

const propTypes = {
  'meta-title': PropTypes.string.isRequired,
  'meta-description': PropTypes.string.isRequired,
  excludeBrandFromMetaTitle: PropTypes.bool,
  hasCanonicalTag: PropTypes.bool,
  noIndex: PropTypes.bool,
  preloadImages: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      media: PropTypes.string,
    }),
  ),
  children: PropTypes.node,
  structuredData: PropTypes.object,
};

/**
 * Returns `${metaTitle} | Plaid` unless excludeBrand is true
 *
 * @param {Object} args
 * @param {string} args.metaTitle
 * @param {boolean} args.excludeBrand - removes branding when true
 * @returns {string}
 */
const makeMetaTitle = ({ metaTitle = '', excludeBrand = false }) => {
  if (excludeBrand) {
    return metaTitle;
  }
  return `${metaTitle} | Plaid`;
};

const MetaTags = ({
  'meta-description': metaDescription,
  excludeBrandFromMetaTitle: excludeBrand = false,
  hasCanonicalTag = true,
  noIndex,
  preloadImages = [],
  children,
  structuredData = {},
  ...props
}) => {
  const { asPath } = useRouter();
  const metaTitle = makeMetaTitle({
    metaTitle: props['meta-title'],
    excludeBrand,
  });
  const parsedPath = parsePath(asPath);
  const pagePath = appendTrailingSlash(parsedPath.pathname);
  const canonicalPagePath = // TODO: remove this after legacy identity product is renamed
    pagePath === '/products/identity/'
      ? '/products/identity-verification/'
      : pagePath;

  return (
    <Head>
      <Fonts />
      <meta charSet='utf-8' />
      <meta httpEquiv='x-ua-compatible' content='ie=edge' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <meta name='msapplication-TileColor' content='#da532c' />
      <meta name='theme-color' content='#ffffff' />
      {noIndex && <meta name='robots' content='noindex, nofollow' />}
      {hasCanonicalTag && (
        <link rel='canonical' href={`https://plaid.com${canonicalPagePath}`} />
      )}
      <link rel='preconnect' href='https://images.ctfassets.net' crossOrigin />
      <link rel='dns-prefetch' href='https://images.ctfassets.net' />
      <link rel='preconnect' href='https://assets.ctfassets.net' crossOrigin />
      <link rel='dns-prefetch' href='https://assets.ctfassets.net' />
      <link rel='preconnect' href='https://videos.ctfassets.net' crossOrigin />
      <link rel='dns-prefetch' href='https://videos.ctfassets.net' />
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/assets/img/favicons/apple-touch-icon.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='/assets/img/favicons/favicon-32x32.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='/assets/img/favicons/favicon-16x16.png'
      />
      <link rel='manifest' href='/assets/img/favicons/site.webmanifest' />
      <link
        rel='mask-icon'
        href={'/assets/img/favicons/safari-pinned-tab.svg'}
        color='#5bbad5'
      />
      {preloadImages?.map((image, i) => {
        return (
          <link
            as='image'
            fetchpriority='high'
            href={optimizeImage(image.src)}
            key={`image-${i}`}
            rel='preload'
            type={image.type || 'image/webp'}
            {...(image?.media && { media: image.media })}
          />
        );
      })}

      <title>{metaTitle}</title>
      {metaDescription && <meta name='description' content={metaDescription} />}
      {OpenGraphData({ metaTitle, metaDescription, pagePath, ...props })}
      <meta
        name='google-site-verification'
        content='92umdhJ1LufsliBdSAFEltp1osgG_ehru4C__nMCJpQ'
      />
      <meta
        name='facebook-domain-verification'
        content='8pfbsxqbxm99hpn4wd4lmfewlc624z'
      />

      <style
        type='text/css'
        dangerouslySetInnerHTML={{
          __html: criticalCss([
            'Touchable',
            'Icon',
            'Button',
            'Grid',
            'Column',
            'Input',
            'Select',
            'Banner',
          ]),
        }}
      />

      <script
        dangerouslySetInnerHTML={{
          __html: `
            !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
            analytics.page();}}();
          `,
        }}
      />

      {isProduction && <script defer src='/assets/js/hire.js'></script>}
      {!isEmpty(structuredData) && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
        />
      )}

      {/*
          we load this mutiny script outside of the ThirdPartyScripts.tsx so
          it will not be loaded asynchronously. It is categorized as strictly
          necessary as it is a dependency of Mutiny and not actually used for
          personalizing the website until a user has consented via scripts loaded in Third
          PartyScrips.tsx and Segment https://plaidinc.atlassian.net/browse/SITE-4954
      */}
      <script
        data-nscript='beforeInteractive'
        id='mutiny-client-script'
        dangerouslySetInnerHTML={{
          __html: `
                (function(){var a=window.mutiny=window.mutiny||{};if(!window.mutiny.client){a.client={_queue:{}};var b=["identify","trackConversion"];var c=[].concat(b,["defaultOptOut","optOut","optIn"]);var d=function factory(c){return function(){for(var d=arguments.length,e=new Array(d),f=0;f<d;f++){e[f]=arguments[f]}a.client._queue[c]=a.client._queue[c]||[];if(b.includes(c)){return new Promise(function(b,d){a.client._queue[c].push({args:e,resolve:b,reject:d});setTimeout(d,500)})}else{a.client._queue[c].push({args:e})}}};c.forEach(function(b){a.client[b]=d(b)})}})();
              `,
        }}
      />

      {children}
    </Head>
  );
};

MetaTags.propTypes = propTypes;

export default MetaTags;
