import * as React from 'react';

const Fonts = () => {
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
  @font-face {
    font-family: Cern;
    font-style: normal;
    font-weight: 800;
    src: url('/assets/fonts/cern-extrabold.woff2') format('woff2');
    font-display: swap;
  }

  @font-face {
    font-family: Cern;
    font-style: italic;
    font-weight: 800;
    src: url('/assets/fonts/cern-extrabolditalic.woff2') format('woff2');
    font-display: swap;
  }

  @font-face {
    font-family: Cern;
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/cern-regular.woff2') format('woff2');
    font-display: swap;
  }

  @font-face {
    font-family: Cern;
    font-style: normal;
    font-weight: 500;
    src: url('/assets/fonts/cern-medium.woff2') format('woff2');
    font-display: swap;
  }

  @font-face {
    font-family: Cern;
    font-style: italic;
    font-weight: 500;
    src: url('/assets/fonts/cern-mediumitalic.woff2') format('woff2');
    font-display: swap;
  }

  @font-face {
    font-family: Cern;
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/cern-semibold.woff2') format('woff2');
    font-display: swap;
  }

  @font-face {
    font-family: Cern;
    font-style: italic;
    font-weight: 600;
    src: url('/assets/fonts/cern-semibolditalic.woff2') format('woff2');
    font-display: swap;
  }

  @font-face {
    font-family: Cern;
    font-style: italic;
    font-weight: 400;
    src: url('/assets/fonts/cern-italic.woff2') format('woff2');
    font-display: swap;
  }

  @font-face {
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/Inconsolata-Regular.woff2') format('woff2');
    font-display: swap;
  }

  @font-face {
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/Inconsolata-Bold.woff2') format('woff2');
    font-display: swap;
  }

  @font-face {
    font-family: 'BoweryStreetTrial';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/BoweryStreetTrial-Light.woff2') format('woff2');
    font-display: swap;
  }

  @font-face {
    font-family: 'BoweryStreetTrial';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/BoweryStreetTrial-Regular.woff2') format('woff2');
    font-display: swap;
  }

  @font-face {
    font-family: 'BoweryStreetTrial';
    font-style: normal;
    font-weight: 500;
    src: url('/assets/fonts/BoweryStreetTrial-Medium.woff2') format('woff2');
    font-display: swap;
  }

  @font-face {
    font-family: 'BoweryStreetTrial';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/BoweryStreetTrial-Bold.woff2') format('woff2');
    font-display: swap;
  }

  h1, h2, h3, h4, h5, p, a, button, b, strong, em, span, q, figcaption, cite {
    font-family: Cern, Helvetica, Arial, sans-serif;
  }

  h6 {
    font-family: Inconsolata, Consolas, Courier, monospace;
  }
  `,
      }}
    />
  );
};

export default Fonts;
